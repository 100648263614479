/**
 * This Wizard component is the empty content of the wizard tab module wizard-externalLinkTab-pwa.
 * FM Module Link: https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/296
 * This is used for the mapping needed in
 * /src/components/flexFramework/FlexComponents/flexComponentMap.ts
 * The module can be customized in Flex Manager or/and in local site overrides config files as follows:
 * - custom icon from the Uitk Icons library
 * - custom label token from the blossom-flex-ui-localization
 * - custom URL that it will open in a new window when the user clicks on the tab
 */
import * as React from "react";
import { WizardExternalLinkTabPWAProps } from "./typings";
import { withStores } from "stores";

export const WizardExternalLinkTabPWA = withStores("flexModuleModelStore")((props: WizardExternalLinkTabPWAProps) => {
  return <></>;
});

export default WizardExternalLinkTabPWA;
